<template>
  <div style="max-width: 500px" class="mx-auto py-8 px-12">
    <h2 align="center"
        class="mt-16 mb-2"
        style="font-size: 30px">
      {{$t('features.form_title_bold')}}
      <span style="font-weight: 300"> {{$t('features.form_title')}} </span>
    </h2>
    <p class="body-1 text-center mb-8">{{ $t('features.form_subtitle') }}</p>

    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="submitForm"
    >
      <v-text-field
          v-model="form.name"
          :rules="[rules.required, rules.length30]"
          counter
          :label="$t('contact_consultant.name')"
          required
          filled
          rounded
          class="mt-0"
      ></v-text-field>
      <v-textarea
          v-model="form.message"
          :label="$t('contact_consultant.description')"
          :hint="$t('contact_consultant.description_hint')"
          :rules="[rules.required, rules.length300]"
          filled
          counter
          rounded
          style="display: none"
      ></v-textarea>
      <v-text-field
          v-model="form.email"
          :rules="[rules.required, rules.email]"
          :label="$t('contact_consultant.email')"
          type="email"
          :hint="$t('contact_consultant.email_hint')"
          required
          filled
          rounded
      ></v-text-field>
      <v-btn
          class="mr-4 mt-4 create-event-btn"
          type="submit"
          :disabled="!valid"
          color="secondary"
          elevation="0"
          rounded
          block
          large
      >
        {{ $t('features.form_button') }}
      </v-btn>
    </v-form>

    <v-snackbar
        v-model="snackbar"
        :timeout=5000
        :color=snackbarcolor
    >
      <h3 v-html="response"></h3>
      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from 'axios';
import * as config from '@/api/config';
import {validationRules} from '@/utils/validation_rules';
import {generateFormData} from '@/utils/helpers';


export default {
  name: "FeatureForm",
  data() {
    return {
      snackbar: false,
      snackbarcolor: "green",
      valid: true,
      form: {
        name: '',
        email: '',
        message: 'Hallo, ich hätte gerne eine Gutschein für mein nächstes Event in Höhe von 20%.',
      },
      rules: validationRules,
      response: '',
    }
  },
  methods: {
    validate () {
      return this.$refs.form.validate()
    },

    submitForm() {
      // this.form = removeHtml(this.form)
      if(this.validate())
      {
        const formData = generateFormData(this.form)
        this.snackbarcolor = 'success';
        axios.post(config.KUMSCHO_API_SERVER + '/mail/contact', formData).then(response => {
          this.displayResponse(response.data)
        }).catch(error => {
          let msg = error.message ? error.message : error.response.statusText
          this.displayResponse('Error: ' + msg)
        }).finally(() => {
          this.snackbar = true
        });
      }
    },
    displayResponse(res) {
      if (res == 'Ok') {
        res = 'Das E-Mail wurde gesendet'
        this.$refs.form.reset()
      }
      else this.snackbarcolor = 'error';
      this.response = res;
    }
  }
}
</script>

<style scoped>

</style>